<template>
  <div>
    <v-search
      v-if="withFilter"
      v-model="filterValue"
      inline
      :placeholder="$t('components.forms.vMultiRadio.placeholder')"
      @clear="() => (filterValue = null)"
    />
    <div
      v-if="filteredOptions.length"
      :class="['options-list', { '--with-filter': withFilter }]"
    >
      <v-radio
        v-for="(option, k) in filteredOptions"
        :key="k"
        :current-value="currentValue"
        :allow-multiple="allowMultiple"
        :value="String(option.value)"
        @change="value => $emit('change', value)"
      >
        <label>{{ option.value }}</label>
      </v-radio>
    </div>
    <div v-else class="empty-state d-flex align-items-center flex-column py-5">
      <v-icon icon="search_off" size="3rem" class="text-grey-50" />
      <div class="text-grey-70 mt-2 font-weight-semi-bold text-center">
        {{ $t("components.forms.vMultiRadio.emptyState") }}
      </div>
    </div>
  </div>
</template>

<script>
import vSearch from "@/components/forms/vSearch"
import vRadio from "@/components/forms/vRadio"

export default {
  components: {
    vSearch,
    vRadio
  },

  data() {
    return {
      filterValue: null
    }
  },

  props: {
    options: { type: Array, default: () => [] },
    currentValue: { type: [String, Boolean, Number], default: "" },
    withFilter: { type: Boolean, default: false },
    allowMultiple: { type: Boolean, default: false }
  },

  computed: {
    filteredOptions() {
      return this.filterValue
        ? this.options.filter(
            option =>
              option.value &&
              option.value
                .toLowerCase()
                .includes(this.filterValue.toLowerCase())
          )
        : this.options
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.options-list {
  &.--with-filter {
    padding-bottom: $size--4;
    max-height: min(
      50vh,
      400px
    ); // 50% of viewport height or height of 10 options
    overflow-y: auto;
    mask-image: linear-gradient(
      to bottom,
      rgb(255, 255, 255) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}
</style>
