import { AppGlobalState } from "@shared/types"

declare global {
  interface Window {
    FIT_globalState?: AppGlobalState
    FIT_isAppStore?: boolean
  }
}

export const VARIABLES = {
  GLOBAL_STATE: "FIT_globalState",
  IS_APP_STORE: "FIT_isAppStore",
  STRIPE: "FIT_Stripe"
} as const

export const globalState = (): any => window[VARIABLES.GLOBAL_STATE]
export const isAppStore = (): boolean => !!window[VARIABLES.IS_APP_STORE]
