export const PLAYER_EVENTS = {
  ADD: "add",
  UPDATE: "update",
  INITIALIZE: "initialize",
  UNLOAD: "unload",
  READY: "ready",
  ERROR: "error",
  // control
  PLAY: "play",
  PAUSE: "pause",
  STOP: "stop",
  TIME_UPDATE: "timeupdate",
  VOLUME_CHANGE: "volumechange",
  RATE_CHANGE: "ratechange",
  SEEK: "seek",
  ENDED: "ended",
  REPLAY: "replay",
  REWIND: "rewind",
  FORWARD: "forward",
  VOLUMECHANGE: "volumechange",
  PROGRESS: "progress",
  BUFFERING: "buffering",
  WAITING: "waiting",
  CAN_PLAY: "canplay"
}

export const ERRORS = {
  NOT_ALLOWED_ERROR: "NotAllowedError",
  HLS_ERROR: "HlsError"
}
