var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    {
      '--vertical': _vm.isVerticalCard,
      '--horizontal': _vm.isHorizontalCard,
      '--raised': _vm.isRaised,
      '--invert-colors': !_vm.isRaised && _vm.invertColors,
      '--completed': _vm.isPageCompleted,
      '--event': _vm.isEventOccurrence,
      '--no-image': !_vm.showImage,
      '--no-uppercase': _vm.noUppercase
    },
    'collection-item p-relative',
    _vm.cssClass
  ],attrs:{"data-page-id":_vm.pageId}},[_c('v-link',{staticClass:"h-100",attrs:{"is-attachment":_vm.isAttachment,"with-access-checks":_vm.pageId && !_vm.unlock,"page-id":_vm.pageId,"parent-page-id":_vm.parentPageId,"force-lock":_vm.isBoolean(_vm.unlock) ? !_vm.unlock : false,"to":_vm.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var isLocked = ref.isLocked;
  var isLoadingAccess = ref.isLoadingAccess;
  var availableAfter = ref.availableAfter;
return [(_vm.shouldShowCountdown && !_vm.isHorizontalCard && _vm.showImage)?[_c('countdown',{attrs:{"is-loading":false,"overlay":_vm.isVerticalCard,"starts-at":_vm.schedule.starts_at,"ends-at":_vm.schedule.ends_at}})]:_vm._e(),_c('div',{staticClass:"collection-item__content"},[(_vm.isDateCardVariant)?[(_vm.schedule && _vm.schedule.starts_at)?_c('event-occurrence-date',{attrs:{"date":_vm.schedule.starts_at}}):_vm._e()]:(_vm.showImage)?[(_vm.$slots.image)?[_vm._t("image")]:_c('div',{staticClass:"collection-item__image-wrapper p-relative",style:({
              width: _vm.imageWidth,
              height: _vm.imageHeight
            })},[_c('div',{staticClass:"p-absolute collection-item__image",style:({
                backgroundImage: _vm.isPresent(_vm.itemImage)
                  ? ("url(" + _vm.itemImage + ")")
                  : false
              })},[(_vm.showPlayButton)?_c('div',{staticClass:"p-overlay"},[_c('play-button')],1):_vm._e()])])]:_vm._e(),_c('div',{staticClass:"collection-item__details p-relative",style:({
            width: _vm.detailsWidth
          })},[_c('div',{staticClass:"collection-item__details-body d-flex justify-content-between w-100"},[_c('div',{style:({
                maxWidth:
                  (_vm.showPrice && _vm.isPresent(_vm.product)) || _vm.showBookedBadge
                    ? '75%'
                    : '100%'
              })},[(_vm.showPreTitle && _vm.hasDate)?_c('div',{staticClass:"collection-item__pretitle"},[(_vm.isEventOccurrence)?_c('event-occurrence-pretitle',{attrs:{"starts-at":_vm.schedule.starts_at,"ends-at":_vm.schedule.ends_at,"is-all-day":_vm.schedule.is_all_day}}):(_vm.showTime)?_c('scheduled-page-pretitle',{attrs:{"starts-at":_vm.schedule.starts_at,"ends-at":_vm.schedule.ends_at,"show-only-time":_vm.showOnlyTime,"is-all-day":_vm.schedule.is_all_day,"show-all-day":_vm.isEventOccurrence}}):_vm._e()],1):_vm._e(),(_vm.isPresent(_vm.title))?_c('h3',{staticClass:"collection-item__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(
                  (_vm.isPresent(_vm.type) || _vm.isPresent(_vm.contentType)) &&
                    !_vm.isEventOccurrence &&
                    !_vm.isGenericPage
                )?_c('h6',{staticClass:"collection-item__type"},[_vm._v(" "+_vm._s(_vm.contentType ? _vm.contentType.replaceAll("-", " ") : _vm.type)+" ")]):_vm._e(),(_vm.isPresent(_vm.subtitle))?_c('p',{staticClass:"collection-item__subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]),(!isLoadingAccess)?_c('div',{staticClass:"d-flex justify-content-center flex-column"},[(_vm.showPrice && _vm.isPresent(_vm.product))?_c('div',{staticClass:"collection-item__price"},[_vm._v(" "+_vm._s(("" + (_vm.product.symbol || "$") + (_vm.product.price_dollars)))+" ")]):_vm._e(),(_vm.showBookedBadge)?_c('div',{staticClass:"collection-item__book"},[(_vm.isSubscribedToPage(_vm.pageId))?_c('div',{staticClass:"badge badge-success"},[_vm._v(" "+_vm._s(_vm.$t("components.collectionItem.booked"))+" ")]):_c('div',{staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.$t("components.collectionItem.book"))+" ")])]):_vm._e()]):_vm._e()]),(_vm.showFooter)?_c('div',{staticClass:"collection-item__footer pt-1"},[(_vm.isEventOccurrence)?_c('event-occurrence-footer',{attrs:{"isVirtual":_vm.itemData.is_virtual}}):_vm._e()],1):_vm._e()]),(isLocked || isLoadingAccess || _vm.isLoading)?[_c('access-lock',{attrs:{"is-loading":isLoadingAccess || _vm.isLoading,"overlay":_vm.isVerticalCard,"horizontal":_vm.isHorizontalCard,"available-after":availableAfter}})]:_vm._e(),(!(isLocked || isLoadingAccess) && _vm.isCompletable)?[_c('complete-check',{attrs:{"complete":_vm.isPageCompleted,"overlay":!_vm.isRaised && _vm.isVerticalCard,"horizontal":_vm.isHorizontalCard}})]:_vm._e(),(
            _vm.showProgress &&
              !_vm.showBookedBadge &&
              !_vm.isCompletable &&
              !(isLocked || isLoadingAccess) &&
              _vm.isPresent(_vm.pageProgress) &&
              _vm.pageProgress.has_started
          )?[_c('completion-percent',{attrs:{"percent-complete":_vm.pageProgress.percent_complete}})]:_vm._e()],2)]}}])}),_vm._t("subitems")],2)}
var staticRenderFns = []

export { render, staticRenderFns }