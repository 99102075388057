import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    pastDueSubscriptions: []
  },

  mutations: {
    setPastDueSubscriptions(state, subscriptions) {
      state.pastDueSubscriptions = subscriptions
    }
  },

  actions: {
    purchaseIap(
      _,
      { productId, transactionId, originalTransactionId, email = null }
    ) {
      return ApiClient.post(`/products/${productId}/purchase/iap`, {
        transaction_id: transactionId,
        original_transaction_id: originalTransactionId,
        email: email
      }).then(res => res.data)
    },

    fetchIntent(_, { productId, coupon = null, referral = null }) {
      return ApiClient.post(`/products/${productId}/intent`, {
        coupon,
        referral
      }).then(res => res.data)
    },

    fetchProduct(_, id) {
      return ApiClient.get(`/products/${id}`).then(res => {
        return res.data
      })
    },

    fetchMultipleProducts(_, ids) {
      return ApiClient.get(`/products/list`, { params: { ids } }).then(res => {
        return res.data.products
      })
    },

    hasActivePurchase(_, id) {
      return ApiClient.get(`/products/${id}/has_active_purchase`).then(res => {
        return res.data.has_active_purchase
      })
    },

    applyCoupon(_, { productId, couponCode }) {
      return ApiClient.post(`/products/${productId}/apply_coupon`, {
        coupon_code: couponCode
      })
    },

    paySubscription(_, id) {
      return ApiClient.post(`/subscriptions/${id}/pay`)
    },

    updatePaymentMethod(_, { subscriptionId, paymentMethodId }) {
      return ApiClient.post(
        `/subscriptions/${subscriptionId}/update_payment_method`,
        { payment_method_id: paymentMethodId }
      )
    },

    fetchPurchasedItems(_, productId) {
      return ApiClient.get(`/products/${productId}/purchased_items`).then(
        res => {
          return res.data
        }
      )
    },

    calculateTaxByIp(_, { productId, couponCode }) {
      return ApiClient.post(`/products/${productId}/tax_by_ip`, {
        coupon_code: couponCode
      }).then(res => ({
        tax: res.data.tax,
        noCouponTax: res.data.no_coupon_tax
      }))
    },

    fetchTaxByAddress(_, { productId, couponCode, address }) {
      return ApiClient.post(`/products/${productId}/tax_by_address`, {
        coupon_code: couponCode,
        address
      }).then(res => ({
        clientSecret: res.data.client_secret,
        tax: res.data.tax
      }))
    },

    updateStripeCustomer(_, { address, name }) {
      return ApiClient.post(`/products/update_customer`, { address, name })
    }
  },

  getters: {
    getAppProducts(_, __, state) {
      return state.global?.access.app_products || []
    },

    hasPastDueSubscription(state) {
      return state.pastDueSubscriptions.length > 0
    },

    hasPastDueSubscriptionForProduct(state) {
      return productId =>
        state.pastDueSubscriptions.some(sub => sub.product_id === productId)
    },

    getPastDueSubscriptionForProduct(state) {
      return productId =>
        state.pastDueSubscriptions.find(sub => sub.product_id === productId)
    }
  }
}
