import "@/lib/audio/types"

import { PLAYER_EVENTS, ERRORS } from "@/lib/audio/shared"

import NativePlayer from "@/lib/audio/native-player"
import WebPlayer from "@/lib/audio/web-player"

const SUPPORTED_SPEED_RATE = [0.75, 1, 1.25, 1.5, 2]

const getNextPlaybackSpeed = currentSpeed => {
  // TODOB: specs
  const nextSpeedIndex =
    (SUPPORTED_SPEED_RATE.indexOf(currentSpeed) + 1) %
    SUPPORTED_SPEED_RATE.length

  return SUPPORTED_SPEED_RATE[nextSpeedIndex] || 1
}

export default class AudioPlayer {
  constructor({ native = false }) {
    // Playback Managers
    this.player = native ? new NativePlayer(this) : new WebPlayer(this)

    /** @type {AudioSource|null} */
    this.currentSource = null

    // State
    this.isPlaying = false
    this.isPaused = false
    this.isMuted = false
    this.isLoading = false
    this.hasFinished = false
    this.speed = 1

    this._initialize()
  }

  get currentTime() {
    return this.player.currentTime
  }

  get buffered() {
    return this.player.buffered
  }

  get isDurationAvailable() {
    return !!this.currentSource?.audio?.duration
  }

  get currentTimeAsPercentage() {
    return this.currentTime && this.isDurationAvailable
      ? parseFloat(
          (this.player.currentTime / (this.currentSource.audio.duration || 1)) *
            100
        )
      : 0
  }

  /**
   * Checks if the provided audio is the current audio.
   * @param {string} audioId - An identifier for the audio.
   * @returns {boolean} True if the provided audio is the current audio, false otherwise.
   */
  isCurrentAudio(audioId) {
    return (
      this.currentSource &&
      this.currentSource.audio &&
      audioId === this.currentSource.audio.id
    )
  }

  /**
   * Sets the audio source for the player.
   * @param {AudioSource} source - The source object containing audio and page information.
   */
  setSource(source) {
    if (!source) return
    if (!source.audio) return
    if (this.isCurrentAudio(source.audio.id)) return

    this.isLoading = true

    this.currentSource = source

    this.player.setSource(this.currentSource)
    this.player.setLoop(this.currentSource.audio.loop || false)

    this.isLoading = false
  }

  play() {
    this.player.play()
    this.isLoading = true
    this.isPaused = false

    // isPlaying is set in Event Listener
  }

  pause() {
    this.player.pause()
    this.isPlaying = false
  }

  seek(value) {
    if (!this.isDurationAvailable) return

    this.player.seek((value * this.currentSource.audio.duration) / 100)
  }

  toggle() {
    if (this.hasFinished && !this.isPlaying) {
      this.seek(0)
      this.play()
    } else if (this.isPaused || !this.isPlaying) {
      this.play()
    } else {
      this.pause()
    }
  }

  toggleMuted() {
    const muted = !this.isMuted

    this.player.setMuted(muted)
    this.isMuted = muted
  }

  rewind(seconds) {
    this.player.rewind(seconds)
  }

  forward(seconds) {
    if (!this.isDurationAvailable) return

    this.player.forward(seconds, this.currentSource.audio.duration)
  }

  teardown() {
    if (this.player) this.player.destroy()
  }

  togglePlaybackRate() {
    this.speed = getNextPlaybackSpeed(this.speed)
    this.player.setPlaybackSpeed(this.speed)
  }

  _initialize() {
    this.isLoading = true

    this.player.on(PLAYER_EVENTS.ERROR, e => {
      if (
        [ERRORS.NOT_ALLOWED_ERROR, ERRORS.HLS_ERROR].includes(e.detail.name)
      ) {
        this.isLoading = false
        this.isPaused = true
        this.isPlaying = false
      }
    })

    this.player.on(PLAYER_EVENTS.PLAY, () => {
      this.isPlaying = true
      this.isPaused = false
      this.isLoading = false
      this.hasFinished = false

      this.isMuted = this.player.muted
    })

    this.player.on(PLAYER_EVENTS.ENDED, () => {
      if (!this.loop) {
        this.hasFinished = true
        this.isPlaying = false
        this.isPaused = false
      }
    })

    this.player.on(PLAYER_EVENTS.WAITING, () => {
      this.isLoading = true
    })

    this.player.on(PLAYER_EVENTS.CAN_PLAY, () => {
      this.isLoading = false
    })

    this.player.on(PLAYER_EVENTS.READY, () => {
      this.isLoading = false
    })
  }
}
