<template>
  <div
    v-if="showFitterBadge"
    :class="[
      'made-with-movement',
      {
        '--allow-fixed': allowFixed
      }
    ]"
  >
    <a :href="url" target="_blank" class="d-flex align-items-center">
      <img
        v-if="isDarkMode"
        :src="require('@/assets/images/made-with-movement-white.svg')"
        alt="Made With Movement"
      />
      <img
        v-else
        :src="require('@/assets/images/made-with-movement-black.svg')"
        alt="Made With Movement"
      />
    </a>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { COLOR_MODES } from "@/lib/stream-utils"

export default {
  props: {
    colorModeOverride: { type: String, default: null },
    allowFixed: { type: Boolean, default: true }
  },

  computed: {
    ...mapState(["global"]),
    ...mapGetters(["showFitterBadge", "app"]),

    colorMode() {
      return this.colorModeOverride
        ? this.colorModeOverride
        : this.global?.theme?.default_color_mode
    },

    isDarkMode() {
      return this.colorMode === COLOR_MODES.DARK
    },

    url() {
      return this.app.slug
        ? `https://movement.so?utm_source=${this.app.slug}&utm_campaign=made-with-movement&utm_medium=made-with-movement`
        : `https://movement.so?utm_campaign=made-with-movement&utm_medium=made-with-movement`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.made-with-movement {
  width: 160px;
  margin: $size--10 auto $size--11;

  a {
    @include clickable;

    border-radius: $size--1;
    padding: $size--1;
  }

  img {
    max-width: 100%;
  }
}

@media screen and (max-width: $breakpoint--xl) {
  .made-with-movement {
    transform: none !important;
  }
}

@media screen and (min-width: $breakpoint--xl) {
  .made-with-movement.--allow-fixed {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $tabbar--desktop-width;
    height: $made-with-movement-badge--height;
    padding: 0.75rem;
    margin: 0;
    left: 0;
    bottom: 0;
    box-shadow: 0 -1px 3px 0 rgba($color-rgb--grey-80, 0.1);

    a {
      display: block;
    }
  }
}
</style>
